import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useGoBack from '../../../hooks/useGoBack';
import Button, { ButtonType, ButtonSize } from '../form-control/Button';
import { ChevronIcon, ChevronType } from '../icon/ChevronIcon';

type BreadCrumbBackButtonProps = {
  onClick: () => void;
};

/**
 * Provides a Breadcrumb-style button that goes back to the previous page when clicked
 * @param onClick Will be called on click of the back button. Overrides the default goBack functionality.
 */
export const BreadCrumbBackButton: FC<BreadCrumbBackButtonProps> = (props) => {
  const { onClick } = props;
  const { t } = useTranslation('common');
  const goBack = useGoBack();

  if (!history.length) {
    return <></>;
  }

  return (
    <Button
      type={ButtonType.PRIMARY}
      size={ButtonSize.S}
      ignoreMinWidth
      className="mr-6 flex items-center rounded-md"
      onClick={() => {
        onClick ? onClick() : goBack();
      }}
    >
      <Button.Slot name="Icon">
        <ChevronIcon className="-ml-1 mb-[2px] h-4 w-4" type={ChevronType.LEFT} />
      </Button.Slot>
      <span className="-ml-2">{t('breadcrumb.back')}</span>
    </Button>
  );
};
